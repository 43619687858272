import React from 'react'
import ReactTooltip from 'react-tooltip'
import { GlobalStyle } from '../styles/global'

type GlobalLayoutType = {
  children: React.ReactNode
}

export const GlobalLayout = ({ children }: GlobalLayoutType) => {
  ReactTooltip.rebuild()

  return (
    <>
      <GlobalStyle />
      <div>{children}</div>
      <ReactTooltip effect="solid" className="tooltip" place="right" />
    </>
  )
}
